<template>
  <div id="layout-wrapper">
    <NavBar />
    <SideBar />
    <div class="main-content">
      <router-view ref="view"/>
      <Footer />
    </div>
    <!-- end main content-->
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    SideBar,
    Footer,
  },
  beforeMount(){    
    console.log("Ini halaman HomeView")
  }
};
</script>
