<template>
  <div id="app">
    <HomeView v-if="active" />
    <Login v-else />
  </div>
</template>
<script>
import Login from "@/views/Auth/Login.vue";
import HomeView from "@/views/HomeView.vue";
import { destroySocket, socketOnConnect } from "./utils/chatSocketUtils";
import { getTokens } from "./services/services";
export default {
  components: {
    HomeView,
    Login,
  },
  data() {
    return {
      active: false,
      token: null,
    };
  },  
  created(){
    destroySocket()
    socketOnConnect(getTokens())
  },
  beforeMount() {
    console.log("Ini halaman App!")
    this.token = sessionStorage.getItem("signature");
    if (this.token == null) {
      this.active = false;
    } else {
      this.active = true;
    }
  },
};

var APP_LEVEL = process.env.VUE_APP_LEVEL
var DEBUG

if (APP_LEVEL == 'development') {
    DEBUG = true
} else {
    DEBUG = false
}

if (!DEBUG) {
    if (!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function() {};
    }
}
</script>
