
const signature = sessionStorage.getItem("signature");

function getRole(){     
    const role = signature.charAt(signature.length - 1);
    return role;
}
function getTokens(){    
    const tokens = signature.slice(0, -1);
    return tokens
}
function getUserName(){    
    const userName = sessionStorage.getItem("uname");
    return userName
}

function getUserId(){    
    const userId = sessionStorage.getItem("uid");
    return userId
}



export {
    getRole,
    getTokens,
    getUserName,
    getUserId
}

// commentSampah

// import { generateClientSignature } from "./authentication"

// export async function callAPI(data, prcCode) {
//     const clientSignature = generateClientSignature(data.clientId)
//     console.log("signature", clientSignature)

//     Object.assign(data, { "clientSignature": clientSignature })
//     console.log("data baru: ", data)
//     const response = await fetch(`https://development-flow.com/benings-admin/api/v1/dataHubExt`, {
//         method: 'POST',
//         headers: {
//             'dbcode': 'benings_devel',
//             'procedure-code': prcCode,
//             'Authorization': 'Basic ZGV2bEFQSToxMjM0NTY3ODkw',
//         },
//         body: JSON.stringify(data),
//     });
//     return await response.json();
// }

