import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [{
    path: "/login",
    name: "LoginView",
    component: () => import("../views/Auth/Login.vue"),
    meta:{
      requireToken : true
    }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "*",
    name: "FourOFour",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/city",
    name: "CityView",
    component: () => import("../views/Pages/Address/City/City.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/village",
    name: "VillageView",
    component: () => import("../views/Pages/Address/Village/Village.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/district",
    name: "DistrictView",
    component: () => import("../views/Pages/Address/District/District.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/province",
    name: "ProvinceView",
    component: () => import("../views/Pages/Address/Province/Province.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/print",
    name: "PrintView",
    component: () => import("../views/Pages/Settings/Print/Print.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/user",
    name: "UserView",
    component: () => import("../views/Pages/Administrator/User/User.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/doctor",
    name: "DoctorView",
    component: () => import("../views/Pages/Administrator/Doctor/Doctor.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/Pages/Products/Product/Product.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/bundling",
    name: "bundling",
    component: () => import("../views/Pages/Products/Bundling/Bundling.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/category",
    name: "category",
    component: () => import("../views/Pages/Products/Category/Category.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/type",
    name: "Type",
    component: () => import("../views/Pages/Products/Type/Type.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Apoteker",
    name: "Apoteker",
    component: () => import("../views/Pages/Products/Apoteker/Apoteker.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Banner",
    name: "BannerView",
    component: () => import("../views/Pages/Banner/Banner.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Logistic",
    name: "LogisticView",
    component: () => import("../views/Pages/Logistic/Logistic.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Promotion",
    name: "PromotionView",
    component: () => import("../views/Pages/Promotion/Promotion/Promotion.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Promo-User",
    name: "PromoUserView",
    component: () => import("../views/Pages/Promotion/Promo_User/PromoUser.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Notif",
    name: "NotificationView",
    component: () => import("../views/Pages/Notification/Notification.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/History",
    name: "HistoryView",
    component: () => import("../views/Pages/Information/History/History.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Article",
    name: "ArticleView",
    component: () => import("../views/Pages/Information/Article/Article.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Clinic",
    name: "ClinicView",
    component: () => import("../views/Pages/Information/clinic/Clinic.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Technology",
    name: "TechnologyView",
    component: () => import("../views/Pages/Information/Technology/Technology.vue"),
    meta: {
      requiresAuth : true
    }
  },
  
  {
    path: "/Treatment",
    name: "TreatmentView",
    component: () => import("../views/Pages/Information/Treatment/Treatment.vue"),
    meta: {
      requiresAuth : true
    }
  },
  
  {
    path: "/Payment-Bank",
    name: "PaymentBankView",
    component: () => import("../views/Pages/Payment/Payment_Bank/PaymentBank.vue"),
    meta: {
      requiresAuth : true
    }
  },
  
  {
    path: "/Payment-Method",
    name: "PaymentMethodView",
    component: () => import("../views/Pages/Payment/Payment_Method/PaymentMethod.vue"),
    meta: {
      requiresAuth : true
    }
  },
  {
    path: "/Transaction",
    name: "TransactionView",
    component: () => import("../views/Pages/Transaction/Transaction.vue"),
  },
  {
    path: "/Member",
    name: "MemberView",
    component: () => import("../views/Pages/Transaction/Member/Member.vue"),
  },
  {
    path: "/ereceipt",
    name: "EreceiptView",
    component: () => import("../views/Pages/Ereceipt/Ereceipt.vue"),
  },
  {
    path: "/Chat",
    name: "App-Chat",
    component: () => import("../views/Pages/Chat/Chat.vue"),
    meta: {
      requireAdmin: true
    }
  },
  {
    path: "/Blacklist",
    name: "BlackListMember",
    component: () => import("../views/Pages/Blacklist/BlackListMember.vue"),
    // meta: {
    //   requireAdmin: true
    // }
  },
  {
    path: "/ReportBank",
    name: "ReportBank",
    component: () => import("../views/Pages/Report/Bank/BankReport.vue"),
    meta: {
      // requireAdmin: true
    }
  },
  {
    path: "/ReportMember",
    name: "ReportMember",
    component: () => import("../views/Pages/Report/Member/MemberReport.vue"),
    meta: {
      // requireAdmin: true
    }
  },
  {
    path: "/ReportCourier",
    name: "ReportCourier",
    component: () => import("../views/Pages/Report/Courier/CourierReport.vue"),
    meta: {
      // requireAdmin: true
    }
  },
  {
    path: "/ReportProduct",
    name: "ReportProduct",
    component: () => import("../views/Pages/Report/Product/ProductReport.vue"),
    meta: {
      // requireAdmin: true
    }
  },
  {
    path:"/ReportPromotion",
    name:"ReportPromotion",
    component:()=>import("../views/Pages/Report/Promotion/PromotionReport.vue"),
    meta: {
      //requireAdmin: true
    }
  }
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});



router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){
    
    const clientSignature = sessionStorage.getItem("signature");
    let role = clientSignature.charAt(clientSignature.length - 1);

    if(role != 3){
      next()
    }else{
      next("/ErrorNotFound")
    }
  }else{
    next()
  }

  if(to.matched.some(record => record.meta.requireToken)){
    
    const clientSignature = sessionStorage.getItem("signature");

    if(clientSignature){
      next("/Dashboard")
    }else{
      next("/login")
    }
  }else{
    next()
  }
  
  if(to.matched.some(record => record.meta.requireAdmin)){    
    
    const clientSignature = sessionStorage.getItem("signature");
    let role = clientSignature.charAt(clientSignature.length - 1);

    if(role == 3){
      next()
    }else{
      next("/ErrorNotFound")
    }
  }else{
    next()
  }
})


export default router;