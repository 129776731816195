import axios from 'axios';
import { generateClientSignature } from "./authentication"
import { callJWTAxios } from "./jwtService"
import { encryptWithAES, decryptWithAES} from "./crypto"

let url = process.env.VUE_APP_DEVEL_HOST
let jwtResponse
export async function callAPIAxios(request, files, prcCode) {
    const clientSignature = generateClientSignature(request.clientId)
    
    await callJWTAxios().then(response => {
        jwtResponse = response
    })

    if (jwtResponse != null){
        if (jwtResponse.outError != 0){
            return
        }

        Object.assign(request, { "clientSignature": clientSignature })
        let formData = new FormData();
        if (request.type == "product") {
            for (var i = 0; i < request.file.length; i++) {
                let file = request.file[i];
                formData.append(`file`, file);
            }
        } else {
            formData.append(`file`, request.file);
        }

        request = encryptWithAES(JSON.stringify(request))

        formData.append(`body`, JSON.stringify(request));

        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'dbCode': 'benings_devel',
                'procedure-code': prcCode,
                'Authorization': 'Bearer '+jwtResponse.outToken,
            }
        })
        response.data = JSON.parse(decryptWithAES(response.data))
        return response
    }else{
        console.log("Unautorized!")
        return
    }
}