import socket from "../socket"

export function socketOnConnect(tokens) {
  console.log("Kesenggol nih socketOnConnect")  
  socket.disconnect();
  socket.auth = { token: tokens, level: "admin" }
  socket.connect()
  // Common
  socket.on("connect", () => {
    console.log("Successfully Connected! by socketOnConnect")
  });
  socket.on("success_connect", (val) => {
    console.log("Sukses konek by socketOnConnect", val)      
    console.log('sesudah konek: ', typeof socket, socket)
  })
  socket.on("disconnect", () => {
    console.log("Successfully Disconnected! by socketOnConnect")
  });
  socket.on("connect_error", (err) => {
    console.log(err); 
  });
}
export function destroySocket(){
  console.log("kena destroy dari utils!")
  // Common      
  socket.off("connect");
  socket.off("disconnect");
  socket.off("send_message");        
  socket.off("private_message");
  socket.off("connect_error");  
  socket.off("get_member_history");  
  socket.off("success_connect"); 
  socket.off("get_chat_by_session");
  socket.off("interactive_chat");
  socket.off("get_interactive_chat");
  socket.off("new_interactive_chat");
  socket.off("on_typing");
  socket.off("admin_lock_session");
  socket.off("admin_end_session");
  socket.off("new_idle_chat");
}