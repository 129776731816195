<template>
  <div class="container-fluid p-0">
    <div class="row g-0">
      <div class="col-lg-4">
        <div class="authentication-page-content p-4 d-flex align-items-center">
          <div class="w-100">
            <div class="row justify-content-center">
              <div class="col-lg-9">
                <div>
                  <div class="text-center">
                    <div>
                      <a href="index.html" class="logo"
                        ><img
                          src="assets/images/Beningslogo.jpg"
                          height="180"
                          alt="logo"
                      /></a>
                    </div>

                    <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                    <p class="text-muted">Sign in to continue to Benings.</p>
                  </div>

                  <div class="p-2 mt-5">
                    <form class="form-horizontal" >
                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-user-2-line auti-custom-input-icon"></i>
                        <label for="username">Username</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Enter username"
                          v-model="user"
                          required
                        />
                      </div>

                      <div class="form-group auth-form-group-custom mb-4">
                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                        <label for="userpassword">Password</label>
                        <input
                          v-model="pass"
                          type="password"
                          class="form-control"
                          placeholder="Enter password"
                          required
                        />
                      </div>

                      <div class="custom-control custom-checkbox" hidden>
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline"
                          >Remember me</label
                        >
                      </div>

                      <div class="mt-4 text-center">
                        <button
                          @click.prevent="loginHandler"
                          class="btn btn-secondary w-md waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div class="mt-4 text-center">
                        <a href="auth-recoverpw.html" class="text-muted"
                          ><i class="mdi mdi-lock mr-1"></i> Forgot your
                          password?</a
                        >
                      </div>
                    </form>
                  </div>
                  <p style="color: red; font-size: 15px">{{ message }}</p>
                  <div class="mt-5 text-center">
                    <p>
                      © 2021 Benings. Crafted with
                      <i class="mdi mdi-heart text-danger"></i> by Flowmotion
                      Developer
                    </p>
                    <p>
                      <img style="cursor:pointer;width:150px" src="https://seal.godaddy.com/images/3/en/siteseal_gd_3_h_l_m.gif" onclick="verifySeal();" alt="SSL site seal - click to verify">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="authentication-bg">
          <div class="bg-overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { callAPIAxios } from "@/services/serviceAxios.js";
var response
export default {
  name: "LoginView",

  data() {
    return {
      user: "",
      pass: "",
      message: "",
    };
  },
  methods: {
    loginHandler: function () {
      const request = {
        clientId: "benings",
        userName: this.user,
        userPassword: this.pass,
      };
      callAPIAxios(request,0,'USER ADMIN LOGIN').then(resp => {
        if (resp != null){
          response = resp.data;

          if (response.outError > 0) {
            console.log(response.outMessage)
            this.message = "*" + response.outMessage;
          } else {
            console.log(response)
            const token = response.outToken;
            const type = response.outUserType;
            const signature = token + type;
            sessionStorage.setItem("signature", signature);
            sessionStorage.setItem("uname", this.user);
            sessionStorage.setItem("uid", response.outUserId)
            location.href="/Dashboard";
          }
        }
      });
    },
  },
};
</script>

<style></style>